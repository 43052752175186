import {
    Card,
    CardBody,
    Checkbox,
    Editable,
    EditableInput,
    EditablePreview,
    HStack, Spacer, VStack, Badge, Menu, MenuList, MenuButton, MenuItem,
} from "@chakra-ui/react";
import {CalendarIcon, DeleteIcon, HamburgerIcon} from "@chakra-ui/icons";

const TaskCard = (props) => {
    return (
        <Card variant="outline" onClick={() => console.log("hello")}>
            <CardBody>
                <VStack align={"left"} spacing={4}>
                    <HStack>
                        <Checkbox defaultChecked={props.task.is_completed} onChange={props.updateCompleted}/>
                        <Editable defaultValue={props.task.name} onSubmit={props.updateName} fontWeight={"bold"}>
                            <EditablePreview/>
                            <EditableInput/>
                        </Editable>
                        <Spacer/>
                        <Menu>
                            <MenuButton as={HamburgerIcon} >
                                Actions
                            </MenuButton>
                            <MenuList>
                                <MenuItem onClick={props.deleteTask} icon={<DeleteIcon />}>Delete</MenuItem>
                            </MenuList>
                        </Menu>
                    </HStack>
                    <HStack>
                        <CalendarIcon/> <Badge colorScheme={"green"} fontSize={"xs"}>Due: Today</Badge>
                        <Badge>Category: Personal</Badge><Badge>Project: Test</Badge>
                    </HStack>
                </VStack>
            </CardBody>
        </Card>
    )
};

export default TaskCard