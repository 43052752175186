import './App.css';
import {ChakraProvider} from "@chakra-ui/react";
import SimpleSidebar from "./components/SimpleSidebar.tsx";
import Project from "./components/Project";

function App() {
    return (
        <ChakraProvider>
            <SimpleSidebar>
                <Project id="9a08d3a4-b7a9-4c71-a97b-2c20bbb4de60"/>
            </SimpleSidebar>
        </ChakraProvider>
    );
}

export default App;
