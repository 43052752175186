import React, {useEffect, useReducer, useState} from "react";
import Client from "../client";
import {arrayMove, SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {
    closestCenter,
    DndContext,
    PointerSensor,
    TouchSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {SortableItem} from "./SortableItem";
import {useAuth0} from "@auth0/auth0-react";
import TaskCard from "./TaskCard";
import {Box, Button, Editable, EditableInput, EditablePreview, Stack} from "@chakra-ui/react";
import {AddIcon} from "@chakra-ui/icons";

const Project = (props) => {
        const {user, isAuthenticated, getAccessTokenSilently, isLoading} = useAuth0();
        const [client, setClient] = useState(null);
        const [project, dispatch] = useReducer(reducer, {tasks: []});
        const sensors = useSensors(
            useSensor(PointerSensor, {activationConstraint: {distance: 10}}),
            useSensor(TouchSensor)
        );

        function reducer(project, action) {
            console.debug(action)

            switch (action.type) {
                case 'set': {
                    return action.project
                }
                case 'moveTask': {
                    const oldIndex = project.tasks.findIndex((task) => task.id === action.id);
                    const newIndex = project.tasks.findIndex((task) => task.id === action.to);

                    project.tasks[oldIndex].moved = true

                    let newTasks = arrayMove(project.tasks, oldIndex, newIndex);

                    for (let i = 0; i < newTasks.length; i++) {
                        if (newTasks[i].position !== i + 1) {
                            newTasks[i].position = i + 1;
                        }
                    }

                    return {...project, tasks: newTasks}
                }
                case 'completeTask': {
                    const index = project.tasks.findIndex((task) => task.id === action.id)
                    project.tasks[index].is_completed = action.is_completed
                    project.tasks[index].changed = true

                    return {...project}
                }
                case 'renameTask': {
                    const index = project.tasks.findIndex((task) => task.id === action.id)
                    project.tasks[index].name = action.name
                    project.tasks[index].changed = true

                    return {...project}
                }
                case 'addTask': {
                    const position = project.tasks.length + 1;

                    const newTask = {
                        name: "New Task",
                        position: position,
                        id: `new-${position}`,
                        project_id: props.id,
                        new: true,
                    }

                    return {...project, tasks: [...project.tasks, newTask]}
                }
                case 'replaceTask': {
                    const index = project.tasks.findIndex((task) => task.id === action.id)

                    project.tasks[index] = action.task

                    return {...project}
                }
                case 'deleteTask': {
                    const index = project.tasks.findIndex((task) => task.id === action.id)

                    if(index > 0) {
                        project.tasks[index].deleted = true
                    }

                    return {...project}
                }
                case 'removeTask': {
                    const index = project.tasks.findIndex((task) => task.id === action.id)

                    if(index > 0) {
                        project.tasks.splice(index,1)
                    }

                    return {...project}
                }
                case 'updateProject': {
                    return {...project, ...action.project}
                }
                case 'saveProject': {
                    return {...project, changed: true}
                }
                default: {
                    console.error(`unknown action type: ${action.type}`)
                }
            }
        }

        // this effect is responsible for getting the access token
        useEffect(() => {
            getAccessTokenSilently({
                authorizationParams: {
                    audience: "https://todo-api.krisb.live"
                },
            })
                .then(token => {
                    if (token != null) {
                        setClient(new Client(token));
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        }, [getAccessTokenSilently, user?.sub]);

        useEffect(() => {
            if (client === null) {
                return
            }

            client.getProject(props.id)
                .then(data => {
                    dispatch({type: "set", project: data})
                })
                .catch(e => {
                    console.error(e)
                })
        }, [client, props.id])

        useEffect(() => {
            console.debug('project changed')

            if (client === null) {
                return
            }

            if(project.changed) {
                project.changed = false

                client.updateProject(project)
                    .catch(e => {
                        console.error(e)
                    })
            }

            // determine if any tasks changed
            for (let i = 0; i < project.tasks.length; i++) {
                if (project.tasks[i].moved) {
                    project.tasks[i].moved = false


                    client.moveTask(project.tasks[i])
                        .catch(e => {
                            console.error(e);
                        })
                }

                if (project.tasks[i].changed) {
                    project.tasks[i].changed = false

                    client.updateTask(project.tasks[i])
                        .catch(e => {
                            console.error(e);
                        })
                }

                if (project.tasks[i].new) {
                    project.tasks[i].new = false

                    client.createTask(project.tasks[i])
                        .then(task => {
                            dispatch({type: "replaceTask", id: project.tasks[i].id, task: task})
                        })
                        .catch(e => {
                            console.error(e);
                        })
                }

                if (project.tasks[i].deleted) {
                    client.deleteTask(project.tasks[i])
                        .then(() => {
                            dispatch({type: "removeTask", id: project.tasks[i].id})
                        })
                        .catch(e => {
                            console.error(e);
                        })
                }
            }

        }, [client, project])

        function handleDragEnd(event) {
            const {active, over} = event;

            if (isLoading) {
                return project.tasks
            }

            if (active.id !== over.id) {
                dispatch({type: "moveTask", id: active.id, to: over.id})
            }
        }

        return isAuthenticated && (
            <Box p={4}>
                <Editable value={project.name}
                          fontSize='3xl'
                          fontWeight='bold'
                          mb={4}
                          onChange={(newName) => dispatch({type:"updateProject", project: {name: newName}})}
                          onSubmit={() => dispatch({type: "saveProject"})}>
                    <EditablePreview />
                    <EditableInput />
                </Editable>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={event => handleDragEnd(event)}
                >
                    <SortableContext
                        items={project.tasks}
                        strategy={verticalListSortingStrategy}
                    >
                        <Stack spacing="4" mb="4">
                            {
                                project.tasks.filter(task => !task.deleted).map(task =>
                                    <SortableItem key={task.id} id={task.id}>
                                        <TaskCard
                                            task={task}
                                            updateName={(newName) => dispatch({
                                                type: "renameTask",
                                                id: task.id,
                                                name: newName
                                            })}
                                            updateCompleted={(e) => dispatch({
                                                type: "completeTask",
                                                id: task.id,
                                                is_completed: e.target.checked
                                            })}
                                            deleteTask={() => dispatch({
                                                type: "deleteTask",
                                                id: task.id
                                            })}
                                        />
                                    </SortableItem>
                                )
                            }
                        </Stack>
                    </SortableContext>
                </DndContext>
                <Button leftIcon={<AddIcon/>} onClick={() => dispatch({type: "addTask"})}>Add Task</Button>
            </Box>
        )
    }
;

export default Project;