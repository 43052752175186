class Client {
    constructor(accessToken) {
        this.accessToken = accessToken;
    }

    headers() {
        return {
            Authorization: `Bearer ${this.accessToken}`
        }
    }

    async makeRequest(url, opts) {
        const method = opts.method || "GET"

        return fetch(url, opts)
            .then(response => {
                console.debug(`${method} ${url} ${response.status}`)

                if (response.ok) {
                    return response.json();
                }
            })
    }

    async getProjects() {
        return await this.makeRequest("https://todo-api.krisb.live/projects", {headers: this.headers()})
    }

    async getProject(projectID) {
        return await this.makeRequest(`https://todo-api.krisb.live/projects/${projectID}`, {headers: this.headers()})
    }

    async moveTask(task) {
        const opts = {
            headers: this.headers(),
            method: "PATCH",
            body: JSON.stringify({position: task.position})
        };

        return this.makeRequest(`https://todo-api.krisb.live/projects/${task.project_id}/tasks/${task.id}`, opts)
    }

    async updateTask(task) {
        const opts = {
            headers: this.headers(),
            method: "PATCH",
            body: JSON.stringify({name: task.name, is_completed: task.is_completed})
        };

        return this.makeRequest(`https://todo-api.krisb.live/projects/${task.project_id}/tasks/${task.id}`, opts)
    }

    async updateProject(project) {
        const opts = {
            headers: this.headers(),
            method: "PATCH",
            body: JSON.stringify({name: project.name})
        };

        return this.makeRequest(`https://todo-api.krisb.live/projects/${project.id}`, opts)
    }

    async createTask(task) {
        const opts = {
            headers: this.headers(),
            method: "POST",
            body: JSON.stringify(task)
        };

        return this.makeRequest(`https://todo-api.krisb.live/projects/${task.project_id}/tasks`, opts)
    }

    async deleteTask(task) {
        const opts = {
            headers: this.headers(),
            method: "DELETE",
        };

        return this.makeRequest(`https://todo-api.krisb.live/projects/${task.project_id}/tasks/${task.id}`, opts)
    }
}

export default Client;